import * as React from "react"
import { aboutPageData } from "components/Data/AboutPageData"
import SeoComponent from "components/SeoComponent"
import Layout from "components/Layout"
import HeroBanner from "components/HeroBanner"
import AboutIntroSection from "components/About/AboutIntroSection"
import Team from "components/About/Team"

const AboutPage = props => {
  const page = aboutPageData

  return (
    <Layout pageName="about">
      <SeoComponent
        title="About Us"
        description="WINR Data provides a single access point to global, high-quality reference data via our Data Consortium."
      />
      {page && page.hero_banner && (
        <HeroBanner
          fields={page.hero_banner}
          pageName="about"
          preHeading="About Us"
        />
      )}

      {page && page.intro_section && (
        <AboutIntroSection fields={page.intro_section} />
      )}

      <Team team={page.team} />
    </Layout>
  )
}

export default AboutPage
