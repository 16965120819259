import React from "react"
import styled from "styled-components"
import View from "components/View"
import device from "utils/media"
import LinkedInIcon from "../../../content/assets/icon-linkedin.png"
import ImgMarcelo from "../../../content/assets/marcelo2.jpg"
import ImgMalcolm from "../../../content/assets/malcolm2.jpg"
import ImgScott from "../../../content/assets/scott.jpg"

const TeamWrapper = styled.div`
  .team-member {
    padding: 0 12px 100px 12px;
    @media ${device.md} {
      padding: 0 12px 36px 12px;
    }
  }

  .team-section-heading {
    padding-bottom: 60px;
  }

  .team-member {
    padding: 0 12px 100px 12px;
    @media ${device.md} {
      padding: 0 12px 36px 12px;
    }
  }
`

const TeamMemberDetails = styled.div`
  padding: 18px 0 0 0;
  h5 {
    font-family: ${props => props.theme.font.body};
    font-weight: 600;
    margin-bottom: 6px;
    float: left;
    @media ${device.md} {
      float: none;
      font-family: ${props => props.theme.text.sm};
    }
  }
  .team-member__position {
    float: left;
    clear: left;
    @media ${device.md} {
      float: none;
    }

    display: block;
    font-size: ${props => props.theme.text.sm};
    margin-bottom: 5px;
  }
  .team-member__linkedin {
    float: right;
    @media ${device.md} {
      float: none;
    }

    i {
      width: 24px;
      height: 24px;
      display: block;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;
      &.LinkedIn {
        background-image: url(${LinkedInIcon});
      }
    }
    i:hover {
      opacity: 0.7;
    }
  }
`

const TeamMember = ({ teamMember }) => {
  const getPortrait = slug => {
    let img = null
    if (slug === "marcelo") {
      img = ImgMarcelo
    } else if (slug === "scott") {
      img = ImgScott
    } else if (slug === "malcolm") {
      img = ImgMalcolm
    }
    return img
  }

  const portrait = getPortrait(teamMember.slug)

  return (
    <TeamWrapper as={View} className="col sm-6 md-3">
      <div className="team-member">
        <div className="team-member__portrait">
          <img src={portrait} alt={teamMember.name} />
        </div>
        <TeamMemberDetails>
          <h5>{teamMember.name}</h5>
          <span className="team-member__position">{teamMember.position}</span>
          <a
            className="team-member__linkedin"
            href={teamMember.linkedin_url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i aria-hidden="true" className="LinkedIn"></i>
          </a>
        </TeamMemberDetails>
      </div>
    </TeamWrapper>
  )
}

export default TeamMember
