import React from "react"
import styled from "styled-components"
import TeamMember from "components/About/TeamMember"

const TeamWrapper = styled.section`
  background-color: ${props => props.bgColor};
  color: ${props => props.textColor};
  padding: 100px 0 60px 0;
`

const SubTeam = ({ team, data }) => {
  return (
    <TeamWrapper
      bgColor={data.bgColor}
      textColor={data.textColor}
    >
      <div className="container x-large">
        <div className="row">
          <div className="col md-10">
            <h2 className="team-section-heading">{data.teamTitle}</h2>
          </div>
        </div>
      </div>
      {team &&
      <div className="container">
        <div className="row">
          {team.map((teamMember, i) => {
            return <TeamMember teamMember={teamMember} key={i} />
          })}
        </div>
      </div>
      }
    </TeamWrapper>
  )
}
export default SubTeam
