import React from "react"
import styled from "styled-components"
// import { StaticImage } from "gatsby-plugin-image"
import device from "utils/media"
import FeaturedImage from "../../../content/assets/winr-not-your-average-adtech-company.jpg"

const IntroWrapper = styled.div`
  position: relative;
  background-color: ${props => props.bgColor};
  color: ${props => props.textColor};
  padding: 0 0 0 0;

  .featured_image {
    img {
      z-index: 1;
      position: relative;
    }
    margin-left: 30%;
    margin-top: -30px;

    @media ${device.md} {
      margin-left: 20%;
    }

    @media ${device.lg} {
      margin-left: 0;
    }
  }
`
const FeaturedImageBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => props.bgColor};
  z-index: 0;
  margin-top: -40px;
  margin-left: -40px;
  @media ${device.lg} {
    margin-top: -80px;
    margin-left: -80px;
  }
  width: calc(100% - 10px);
  height: calc(100% - 10px);
`

const IntroContent = styled.div`
  padding: 120px 30px 100px 30px;
  h2,
  h3,
  h4,
  h5 {
    color: #ffffff;
  }
  font-size: ${props => props.theme.text.base};

  h3,
  h4 {
    text-transform: uppercase;
  }

  @media ${device.lg} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  @media ${device.xl} {
    padding-left: 60px;
    padding-right: 60px;
    font-size: ${props => props.theme.text.base_2};
  }
`

const AboutIntroSection = ({ fields }) => {

  return (
    <IntroWrapper bgColor={"#061425"} textColor={"#ffffff"}>
      <div className="container no-max no-padding">
        <div className="row no-gutter">
          <div className="col lg-7">
            {fields.content && (
              <IntroContent>
                {fields.title && (
                  <h2 dangerouslySetInnerHTML={{ __html: fields.title }} />
                )}
                <div dangerouslySetInnerHTML={{ __html: fields.content }} />
              </IntroContent>
            )}
          </div>
          <div className="col lg-offset-1 lg-4 first-xs last-lg">
            <div className="featured_image rel">
              {FeaturedImage && <img src={FeaturedImage} alt="" />}
              <FeaturedImageBackground
                bgColor={props => props.theme.color.light_teal}
              />
            </div>
          </div>
        </div>
      </div>
    </IntroWrapper>
  )
}

export default AboutIntroSection
