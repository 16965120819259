import AwsLogo from "../../../content/assets/powered-by-aws-white.png"
import FingerPrintImg from "../../../content/assets/fingerprint-concept.png"

const aboutPageData = {
  hero_banner: {
    heading: "We're In The Business Of Global Identity Data.",
    background_colour: "#fdfdfd",
    text_colour: "#000",
    include_image_slider: true,
    slider_images: [
      {
        url: FingerPrintImg,
        alt: ''
      }
    ]
  },
  intro_section: {
    title: "Overview",
    content: `<p>Whether it be Identity Verification (IDV) or Identity Resolution (IDR), businesses around the world are looking to build their Identity Data assets. WINR Data provides a single access point to global, high-quality digital and terrestrial data driven identity solutions via our Data Consortium. </p>
      <h3>What is Identity Verification (IDV)?</h3>
      <p>Companies benefit when they know a customer's true identity prior to doing business with them. In many industries, 'knowing your customer' isn't just a best practice, it's a regulatory requirement.</p>
      <p>Identity verification solutions use personal information, such as name, date of birth, email or mobile number, from various data sources to quickly confirm if an individual is who they claim to be. This independent verification helps reduce the risks involved in a digital interaction allowing ecommerce businesses to confidently approve new customers, authorize transactions faster and reduce fraud risk.</p>      
      <h3>What is Identity Resolution (IDR)?</h3>
      <p>Third party cookie deprecation has accelerated the shift by the AdTech industry to adopt identity graphs built around first, second and third-party data. This allows companies to connect many different identifiers from multiple platforms and devices in real-time to enable people-based targeting, personalization, and measurement.</p> 
      <p>WINR Data provides platform vendors with a single access point to our international data consortium; a dynamic linkage repository of global, terrestrial identifiers.</p>     
      <h3>Comfort in Compliance</h3>
      <p>WINR Data works with both data owners and clients to work with data around three key elements: <strong style="color: #29cdc9;">Transparency, Compliance and Consent</strong>. This is the absolute trinity of our data collection and usage protocols.</p>
      <p>Each data partner is taken through a robust onboarding process to ensure that their data is fit for purpose and abides by the relevant privacy regulations of each geography.</p>
      <p>Working with our Compliance Advisory Consultants, we find that Data Partners become even more compliant for future commercial engagement whilst our clients have far greater comfort in compliance.</p>
      <div style="margin: 2rem 0 3rem 0; max-width: 500px;">
      <a href="https://aws.amazon.com/what-is-cloud-computing" title="Powered by AWS Cloud Computing" target="_blank"><img src="${AwsLogo}" alt="Powered by AWS Cloud Computing"></a>
      </div>
      <p>Our information security systems have been implemented so as to deliver an effective privacy and security program consistent with the global data protection requirements.</p>
      <p>All of our solutions are built and hosted on AWS so as to inherit the most comprehensive compliance controls available globally. AWS supports more security standards and compliance certifications than any other offering, including PCI-DSS, HIPAA/HITECH, FedRAMP, GDPR, FIPS 140-2, and NIST 800-171, helping customers satisfy compliance requirements for virtually every regulatory agency around the globe.</p>
      `,
  },
  team: [
    {
      name: "Marcelo Ulvert",
      slug: "marcelo",
      position: "Chief Executive Officer",
      linkedin_url: "https://www.linkedin.com/in/marcelo-ulvert-963481",
    },
    {
      name: "Malcolm Treanor",
      slug: "malcolm",
      position: "Chief Operating Officer",
      linkedin_url: "https://www.linkedin.com/in/malcolmtreanor/",
    },
    {
      name: "Scott Lawrence",
      slug: "scott",
      position: "Chief Technology Officer",
      linkedin_url: "https://www.linkedin.com/in/scottplawrence/",
    },
  ],
}

export { aboutPageData }
