import React from "react"
import styled from "styled-components"
import SubTeam from "components/About/SubTeam"

const TeamsWrapper = styled.section`
  padding: 0;
`

const Team = ({ team }) => {
  return (
    <TeamsWrapper>
      {team && team.length > 0 && (
        <SubTeam
          team={team}
          data={{
            bgColor: "#f8f8f8",
            textColor: "#000000",
            teamTitle: "Our Team",
          }}
        />
      )}
    </TeamsWrapper>
  )
}

export default Team
